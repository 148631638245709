<template>
  <div>
    <a-form
      class="iotplt-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button
                @click="fetchRecentSevenDays"
                :type="quickSelected === '7days' ? 'primary' : ''"
              >
                最近七天
              </a-button>
              <a-button
                @click="fetchRecentThirtyDays"
                :type="quickSelected === '30days' ? 'primary' : ''"
              >
                最近30天
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>

        <a-col :span="7">
          <a-form-item label="日期">
            <a-range-picker
              style="width: 100%"
              v-decorator="['day']"
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <a-spin :spinning="loading">
      <div
        ref="chart"
        style="height:420px;"
      />
    </a-spin>

    <div>
      提示：因运营商接口查询延时等原因，本平台所统计的流量使用数据仅供参考
    </div>
  </div>
</template>

<script>
import { formatYearMonthDay } from '@/utils/time'
import { findSimCardNetworkStatistics } from '@/api/sim_card'

export default {
  name: 'NetworkStatistics',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      statistics: [],
      query: {},
      quickSelected: '7days',
      chart: null,
      isCapacityUnitCount: false,
      form: this.$form.createForm(this, { name: 'network_statistics_search' })
    }
  },
  mounted() {
    this.fetchRecentSevenDays()
  },
  computed: {
    // 指定图表的配置项和数据
    chartOption() {
      const colors = [this.$primaryColor]
      const option = {
        title: {
          show: this.statistics.length === 0,
          textStyle: {
            color: 'grey',
            fontSize: 20
          },
          text: '暂无数据',
          left: 'center',
          top: 'center'
        },
        color: colors,
        legend: {
          data: ['使用流量']
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '流量',
            min: 0,
            position: 'left',
            axisLine: {
              lineStyle: {
                color: colors[0]
              }
            },
            axisLabel: {
              formatter: '{value} MB'
            }
          }
        ],
        series: [
          {
            name: '使用流量',
            type: 'line',
            data: []
          }
        ]
      }

      if (this.isCapacityUnitCount) {
        option.legend.data[0] = '连接次数'
        option.yAxis[0].name = '连接次数'
        option.yAxis[0].axisLabel.formatter = '{value} 次'
        option.series[0].name = '连接次数'
      }

      return option
    }
  },
  methods: {
    handleReset() {
      this.form.resetFields()
      this.fetchRecentSevenDays()
    },

    // 根据数据绘制图表
    drawChart() {
      this.chart ||= this.$echarts.init(this.$refs.chart)
      // 横坐标
      this.chartOption.xAxis[0].data = this.$lodash.map(this.statistics, 'day')
      // 日使用量数据
      this.chartOption.series[0].data = this.$lodash.map(this.statistics, 'usage')
      // 使用刚指定的配置项和数据显示图表
      this.chart.setOption(this.chartOption)
    },

    // 最近七天数据
    fetchRecentSevenDays() {
      this.form.resetFields()
      this.quickSelected = '7days'
      this.query.day_begin = formatYearMonthDay(this.$moment().subtract(6, 'days'))
      this.query.day_end = formatYearMonthDay(this.$moment())
      this.fetchData()
    },

    // 最近30天数据
    fetchRecentThirtyDays() {
      this.form.resetFields()
      this.quickSelected = '30days'
      this.query.day_begin = formatYearMonthDay(this.$moment().subtract(29, 'days'))
      this.query.day_end = formatYearMonthDay(this.$moment())
      this.fetchData()
    },

    handleSearch(e) {
      e.preventDefault()
      this.quickSelected = ''

      const fieldsValue = this.form.getFieldsValue()
      this.query = {
        ...fieldsValue
      }

      // 选择了日期则搜索日期范围数据；未选择则加载七天数据
      if (fieldsValue.day && fieldsValue.day.length === 2) {
        this.query.day_begin = formatYearMonthDay(fieldsValue.day[0])
        this.query.day_end = formatYearMonthDay(fieldsValue.day[1])
      } else {
        this.fetchRecentSevenDays()
      }

      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findSimCardNetworkStatistics(this.id, this.query).then((res) => {
        if (res.code === 0) {
          this.statistics = res.data.statistics
          this.isCapacityUnitCount = res.data.is_capacity_unit_count
          this.drawChart()
        }
        this.loading = false
      })
    }
  }
}
</script>
